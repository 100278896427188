import Vue from 'vue'

Vue.mixin({
  methods: {
    numbersOnly(evt) {
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault()
      } else {
          return true
      }
    },
    async copy( val ) {
      await navigator.clipboard.writeText(val);
      this.$notify({
        type: 'success',
        title: 'Berhasil disalin'
      })
    },
  }
})
import Vue from 'vue'
import VueRouter from 'vue-router'

import Booking from '../views/Booking.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import ('../layouts/Main.vue'),
    children: [
      {
          path: '/',
          redirect: '/1'
      },
      {
          path: '/:kfsId',
          name: 'Booking',
          component: Booking,
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div>
    <section v-if="state != 'payment'">
      <div class="hero">
        <div class="container">
          <div class="header-booking">
            <a href="index.html" class="logo-booking d-inline-block">
              <img src="/img/logo/logo-white.svg" class="w-100">
            </a>

            <div class="menu-wrapper">
              <div id="menu" class="menu">
                <div id="menu-header" class="d-flex justify-content-between p-4 align-items-center">
                  <div class="col-6">
                    <a href="index.html"><img src="/img/logo/logo-text.svg" class="img-fluid"></a>
                  </div>
                  <button class="btn btn-close p2-0 px-0"></button>
                </div>
                <ul class="main-menu">
                  <li><a href="#" class="">About Us</a></li>
                  <li><a href="#" class="">Treatment</a></li>
                  <li><a href="#" class="">Our Spot</a></li>
                  <li><a href="#" class="">Contact</a></li>
                  <li><a href="#" class="">Blog</a></li>
                </ul>
              </div>
              <button class="btn btn-toggle"><span class="icon-ico-toggle"></span></button>
            </div>
          </div>

          <div class="cap-hero">
            <h1 class="color-white mb-4 mb-sm-4">Perawatan Terbaik Untuk Kulit Kamu</h1>
            <h2 class="color-white text-bold">#CaraMudah&HematTampilCantik</h2>
          </div>
        </div>
      </div>

      <div class="main-content">
        <div class="container">
          <div class="col-xl-10 offset-xl-1">
            <div class="box-white rounded-10 shadow p-60 ">
              <div class="row justify-content-between align-items-center mb-4 mb-sm-5">
                <div class="col-sm-5">
                  <h3 class="mb-0 text-bold text-center-m mb-4 mb-sm-0" v-if="state == 'treatment'">Pilih Treatment</h3>
                  <h3 class="mb-0 text-bold text-center-m mb-4 mb-sm-0" v-if="state == 'form'">Formulir Booking</h3>
                </div>
                <div class="col-sm-7">
                  <div id="step" class="d-flex justify-content-end align-items-center">
                    <a @click="state = 'treatment'" v-if="state == 'form'" class="text-sm color-gray-80 text-medium pe-2 cursor-pointer">Kembali</a>
                    <div class="step mx-1 current"></div>
                    <div class="step mx-2" :class="state == 'form' ? 'current' : ''"></div>
                    <div class="text-sm color-gray-80 text-medium">
                      {{ state == 'treatment' ? '1' : '2' }}/2 Page
                    </div>
                  </div>
                </div>
              </div>

              <section v-if="state == 'treatment'">
                <div class="row gx-sm-4 gx-xl-5">
                  <div class="col-sm-6" v-for="treatment in treatments" :key="treatment.id">
                    <div class="treatment-choice mb-3">
                      <input type="checkbox" :id="'choice-' + treatment.id" class="hidden"
                        :checked="selectedTreatments.map( x => x.id ).indexOf(treatment.id) != -1"
                        @change="selectUnselectTreatment($event, treatment)">
                      <label :for="'choice-' + treatment.id" class="deco deco-1">
                        <div class="d-flex">
                          <img :src="treatment.collectionImage && treatment.collectionImage.length > 0 ? treatment.collectionImage[0] : '/img/user-1.jpg'" class="treatment">
                          <div class="col-10 ps-3">
                            <div class="text-base text-medium text-hidden">{{ treatment.name }}</div>
                            <ul class="info">
                              <li><span>{{ treatment.durationHours ? treatment.durationHours : 0 }} Jam {{ treatment.durationMinutes ? treatment.durationMinutes : 0 }} Mnt</span></li>
                              <li><div class="color-red-100 text-semiBold">{{ treatment.price | currency }}</div></li>
                            </ul>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <p class="text-center text-sm" v-if="treatments.length == 0">Tidak ada treatment</p>
                <p class="text-center text-sm" v-if="treatments.length > 0 && treatmentCurrentPage < treatmentTotalPage">
                  <a class="color-gray-100" @click="getTreatment( treatmentCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
                </p>

                <hr class="dashed my-4">

                <div class="text-lg text-semiBold mb-4">Treatment Terpilih</div>

                <div class="box-border mb-4 mb-sm-5">
                  <section v-if="selectedTreatments.length > 0">
                    <div class="text-sm text-semiBold mb-3">{{ selectedTreatments.length }} Terpilih</div>
                    <div class="d-flex justify-content-between align-items-center"
                      v-for="treatment in selectedTreatments" :key="treatment.id">
                      <div class="text-sm color-gray-60">{{ treatment.name }}</div>
                      <div class="col-auto d-flex align-items-center">
                        <div class="text-sm color-red-100 text-bold mx-2">{{ treatment.price | currency }}</div>
                        <button class="btn p-0" type="button" @click="unselectTreatment(treatment)"><span
                            class="icon-ico-minus-circle text-lg color-gray-60"></span></button>
                      </div>
                    </div>
                  </section>
                  <div class="text-sm text-center" v-else>Tidak ada treatment terpilih</div>
                </div>

                <div class="col-sm-4 offset-sm-4">
                  <button @click="state = 'form'" :disabled="selectedTreatments.length == 0" class="btn btn-primary w-100">Selanjutnya</button>
                </div>
              </section>
              <section v-if="state == 'form'">
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <form @submit.prevent="createBookingTreatment()">
                    <div class="row ">
                      <div class="col-sm-6">
                        <ValidationProvider name="Nama" v-slot="{ errors }" rules="required" ref="name">
                          <div class="position-relative mb-3">
                            <label class="text-sm color-gray-60 text-medium mb-2">Nama</label>
                            <input type="text" class="form-control" name="" placeholder="" v-model="model.customer.name">
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </div>
                        </ValidationProvider>
                      </div>

                      <div class="col-sm-6">
                        <ValidationProvider name="Nomor handphone" v-slot="{ errors }" rules="required|min:8|max:15" ref="phoneNumber">
                          <div class="position-relative mb-3">
                            <label class="text-sm color-gray-60 text-medium mb-2">Nomor Handphone</label>
                            <input type="text" class="form-control" name="" placeholder="" v-model="model.customer.phoneNumber" @keypress="numbersOnly($event)">
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </div>
                        </ValidationProvider>
                      </div>

                      <div class="col-sm-6">
                        <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email" ref="email">
                          <div class="position-relative mb-3">
                            <label class="text-sm color-gray-60 text-medium mb-2">Email</label>
                            <input type="email" class="form-control" name="" placeholder="" v-model="model.customer.email">
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </div>
                        </ValidationProvider>
                      </div>

                      <div class="col-sm-6">
                        <ValidationProvider name="Tanggal lahir" v-slot="{ errors }" rules="required" ref="dob">
                          <div class="position-relative mb-3">
                            <label class="text-sm color-gray-60 text-medium mb-2">Tanggal Lahir</label>
                            <div class="position-relative">
                              <div class="icn-right calendar">
                                <date-picker v-model="model.customer.dob" :config="datePickerDobOptions" placeholder="Pilih Tanggal"></date-picker>
                              </div>
                            </div>
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </div>
                        </ValidationProvider>
                      </div>

                      <div class="col-sm-6">
                        <ValidationProvider name="Jenis kelamin" v-slot="{ errors }" rules="required" ref="gender">
                          <div class="position-relative mb-3">
                            <label class="text-sm color-gray-60 text-medium mb-2">Jenis Kelamin</label>
                            <div class="position-relative">
                              <div class="icn-right chevron">
                                <select class="form-select" v-model="model.customer.gender">
                                  <option :value="null">Pilih Jenis Kelamin</option>
                                  <option value="laki">Laki-laki</option>
                                  <option value="perempuan">Perempuan</option>
                                </select>
                              </div>
                            </div>
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </div>
                        </ValidationProvider>
                      </div>

                      <div class="col-sm-12">
                        <ValidationProvider name="Alamat" v-slot="{ errors }" rules="required" ref="address">
                          <div class="position-relative mb-3">
                            <label class="text-sm color-gray-60 text-medium mb-2">Alamat</label>
                            <textarea class="form-control" rows="4" v-model="model.customer.address"></textarea>
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </div>
                        </ValidationProvider>
                      </div>

                      <div class="col-sm-12">
                        <div class="text-lg text-semiBold mb-3 mt-4">Waktu Booking</div>
                      </div>

                      <div class="col-sm-6">
                        <ValidationProvider name="Tanggal" v-slot="{ errors }" rules="required" ref="startDate">
                          <div class="position-relative mb-3">
                            <label class="text-sm color-gray-60 text-medium mb-2">Tanggal</label>
                            <div class="position-relative">
                              <div class="icn-right calendar">
                                <date-picker v-model="model.bookingDate" :config="datePickerOptions" placeholder="Pilih Tanggal"></date-picker>
                              </div>
                            </div>
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </div>
                        </ValidationProvider>
                      </div>

                      <div class="col-sm-6" v-if="showTimePicker">
                        <ValidationProvider name="Jam mulai" v-slot="{ errors }" rules="required" ref="startDate">
                          <div class="position-relative mb-3">
                            <label class="text-sm color-gray-60 text-medium mb-2">Jam Mulai</label>
                            <div class="position-relative">
                              <div class="icn-right clock">
                                <date-picker v-model="model.bookingTime" :config="timePickerOptions" placeholder="Pilih Jam"></date-picker>
                              </div>
                            </div>
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </div>
                        </ValidationProvider>
                      </div>

                      <div class="col-sm-12">
                        <div class="position-relative mb-3">
                          <label class="text-sm color-gray-60 text-medium mb-2">Keluhan</label>
                          <textarea class="form-control" rows="4" v-model="model.description"></textarea>
                        </div>
                      </div>
                    </div>

                    <hr class="dashed my-4">

                    <div class="text-lg text-semiBold mb-4">Rincian Booking</div>

                    <div class="box-border mb-4 mb-sm-5">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <div class="text-sm color-gray-60">Treatment</div>
                        <div class="text-sm color-gray-100 text-semiBold">{{ selectedTreatments.map( x => x.name ).join(", ") }}</div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <div class="text-sm color-gray-60">Harga</div>
                        <div class="text-sm color-gray-100 text-semiBold">{{ totalPrice | currency }}</div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <div class="text-sm color-gray-60">Durasi</div>
                        <div class="text-sm color-gray-100 text-semiBold">{{ convertMinute(totalDuration) }}</div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <div class="text-sm color-gray-60">Waktu Booking</div>
                        <div class="text-sm color-gray-100 text-semiBold" v-if="model.bookingDate && model.bookingTime">{{ (model.bookingDate + ' ' + model.bookingTime) | moment('DD MMM YYYY, HH:mm')}} WIB</div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <div class="text-sm color-gray-60">Down Payment</div>
                        <div class="text-sm color-gray-100 text-semiBold">{{ totalPrice * 0.3 | currency }}</div>
                      </div>
                      <hr class="dashed">
                      <div class="d-flex justify-content-between align-items-center" 
                        v-for="treatment in selectedTreatments" :key="treatment.id">
                        <div class="text-sm color-gray-60">{{ treatment.name }}</div>
                        <div class="text-sm color-red-100 text-bold">{{ treatment.price | currency }}</div>
                      </div>
                      <hr class="dashed">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="text-sm color-gray-60">Total Payment</div>
                        <div class="text-sm color-red-100 text-bold">{{ totalPrice | currency }}</div>
                      </div>
                    </div>

                    <div class="col-sm-4 offset-sm-4">
                      <button class="btn btn-primary w-100 btn-pay" type="button" v-if="!invalid">Pilih Metode Pembayaran</button>
                      <button class="btn btn-primary w-100" type="submit" v-else>Pilih Metode Pembayaran</button>
                    </div>
                  </form>
                </ValidationObserver>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-else-if="state == 'payment' && paymentDetail">
      <div class="header-booking box-white shadow-header">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <a href="index.html" class="logo-booking d-inline-block">
              <img src="/img/logo/logo.svg" class="w-100">
            </a>

            <div class="menu-wrapper invert">
              <div id="menu" class="menu">
                <div id="menu-header" class="d-flex justify-content-between p-4 align-items-center">
                  <div class="col-6">
                    <a href="index.html"><img src="/img/logo/logo-text.svg" class="img-fluid"></a>
                  </div>
                  <button class="btn btn-close p2-0 px-0"></button>
                </div>
                <ul class="main-menu invert">
                  <li><a href="#" class="">About Us</a></li>
                  <li><a href="#" class="">Treatment</a></li>
                  <li><a href="#" class="">Our Spot</a></li>
                  <li><a href="#" class="">Contact</a></li>
                  <li><a href="#" class="">Blog</a></li>
                </ul>
              </div>
              <button class="btn btn-toggle"><span class="icon-ico-toggle"></span></button>
            </div>
          </div>
        </div>
      </div>

      <div class="content my-5">
        <div class="container">
          <div class="col-xl-6 offset-xl-3 col-sm-6 offset-sm-3">
            <div class="rounded-16 bg-pay shadow p-60 ">
              <div class="row">
                <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                  <div class="text-center">
                    <div class="text-md text-semiBold mb-2">Booking Berhasil Dibuat</div>
                    <p class="text-sm color-gray-40">Segera lakukan pembayaran sebelum</p>
                    <div class="text-sm color-red-100 text-semiBold">{{ booking.paymentDetail.expired_payment_time | moment('DD MMMM YYYY, HH:mm')}} WIB</div>
                    <hr class="dashed">
                    <p class="text-sm color-gray-40 mb-2">Metode Pembayaran</p>
                    <div class="center-content mb-3">
                      <div class="me-2"><img :src="paymentDetail.icon" class="img-fluid" style="max-width: 45px;"></div>
                      <div class="text-sm">{{ paymentDetail.name }}</div>
                    </div>
                    <section v-if="paymentDetail.va">
                      <p class="text-sm color-gray-40 mb-2">Nomor Virtual Akun</p>
                      <div class="center-content mb-3">
                        <div class="text-sm text-bold">{{ paymentDetail.va }}</div>
                        <a class="text-medium text-sm mx-2 cursor-pointer" @click="copy(paymentDetail.va)">Copy</a>
                      </div>
                    </section>
                    <section v-if="paymentDetail.bill_key">
                      <p class="text-sm color-gray-40 mb-2">Kode Tagihan</p>
                      <div class="center-content mb-3">
                        <div class="text-sm text-bold">{{ paymentDetail.bill_code }}</div>
                        <a class="text-medium text-sm mx-2" @click="copy(paymentDetail.bill_code)">Copy</a>
                      </div>
                      <p class="text-sm color-gray-40 mb-2">Nomor Virtual Akun</p>
                      <div class="center-content mb-3">
                        <div class="text-sm text-bold">{{ paymentDetail.bill_key }}</div>
                        <a class="text-medium text-sm mx-2" @click="copy(paymentDetail.bill_key)">Copy</a>
                      </div>
                    </section>
                    <section v-else-if="paymentDetail.qr">
                      <p class="text-sm color-gray-40 mb-2">Scan QR</p>
                      <div class="center-content">
                        <img :src="paymentDetail.qr" alt="" class="img-fluid">
                      </div>
                      <p class="text-center mb-3"><a class="text-medium text-sm mx-2" @click="copy(paymentDetail.deepLink)">Copy</a></p>
                    </section>
                    <hr class="dashed">
                    <p class="text-sm color-gray-40 mb-2">Total pembayaran</p>
                    <div class="text-sm color-red-100 text-semiBold">{{ booking.downpayment | currency }}</div>
                    <!-- <div class="pt-5">
                      <router-link :to="'/booking-order/' + booking.salesId" class="btn btn-border-primary w-100">Lihat Detail Booking</router-link>
                      <router-link to="/home" class="w-100 text-center d-block text-sm text-medium py-4">Kembali Ke Home</router-link>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <a @click="refresh()" class="w-100 text-center d-block text-sm text-medium py-5 cursor-pointer">Kembali Ke Home</a>
          </div>
        </div>
      </div>
    </section>

    <div class="overlay-popup ">
      <div id="payment" class="box-md hidden bg-white rounded-12 p-4">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Pilih Metode Pembayaran</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <div class="mb-3" v-for="(paymentType, index) in paymentTypes" :key="index">
            <div class="text-sm text-medium mb-1">{{ paymentType.name }}</div>
            <a @click="setPaymentMethod(paymentMethod)" class="bank-choose cursor-pointer" v-for="(paymentMethod, i) in paymentType.paymentMethods" :key="i">
              <div class="d-flex align-items-center">
                <div class="col-2 me-3" style="width: 45px;">
                  <img :src="paymentMethod.icon" class="img-fluid">
                </div>
                <div class="text-sm color-gray-100">{{ paymentMethod.name }}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- <json-viewer :value="request" :expand-depth="5"></json-viewer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: 'treatment',

      treatments: [],
      treatmentCurrentPage: 1,
      treatmentTotalPage: 1,
      treatmentLimit: 10,
      treatmentSortby: 'id.desc',
      treatmentSearch: '',

      selectedTreatments: [],

      datePickerDobOptions: {
        maxDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },
      
      datePickerOptions: {
        minDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },

      model: {
        bookingDate: null,
        bookingTime: null,
        customer: {
          name: null,
          phoneNumber: null,
          email: null,
          dob: null,
          address: null,
          gender: null
        }
      },
      booking: null,
      paymentDetail: {
        icon: null,
        name: null,
        bank: null,
        va: null,
        bill_code: null,
        bill_key: null,
        qr: null,
        deepLink: null,
      },
      paymentTypes: [
        {
          name: "Virtual Account",
          paymentMethods: [
            {
              id: 2,
              icon: '/img/pay-1.png',
              name: 'BCA Virtual Account',
              bank: 'bca'
            },
            {
              id: 2,
              icon: '/img/pay-2.png',
              name: 'Mandiri Virtual Account',
              bank: 'mandiri'
            },
            {
              id: 2,
              icon: '/img/pay-3.png',
              name: 'BNI Virtual Account',
              bank: 'bni'
            },
            {
              id: 2,
              icon: '/img/bri.png',
              name: 'BRI Virtual Account',
              bank: 'bri'
            },
          ]
        },
        {
          name: "E-Wallet",
          paymentMethods: [
            {
              id: 3,
              icon: '/img/gopay.png',
              name: 'Gopay',
              bank: ''
            },
          ]
        },
        {
          name: "Kartu Kredit",
          paymentMethods: [
            {
              id: 4,
              icon: '/img/pay-12.png',
              name: 'Visa'
            },
          ]
        },
      ],
      selectedPaymentMethod: null,
      showTimePicker: true
    }
  },
  computed: {
    timePickerOptions: function() {
      let minDate = null

      if ( this.model.bookingDate == this.$moment().format('YYYY-MM-DD') ) {
        minDate = this.$moment()
      }

      if ( minDate ) {
        return {
          minDate: minDate,
          format: 'HH:mm',
          useCurrent: true,
          stepping: 30
        }
      } else {
        return {
          format: 'HH:mm',
          stepping: 30,
        }
      }
    },
    totalPrice: function() {
      let price = 0
      this.selectedTreatments.forEach(elm => {
        price += parseInt(elm.price)
      });
      return price
    },
    totalDuration: function() {
      let duration = 0
      this.selectedTreatments.forEach(elm => {
        duration += parseInt(elm.durationHours ? elm.durationHours : 0) * 60 + parseInt(elm.durationMinutes ? elm.durationMinutes : 0)
      });
      return duration
    },
    request: function() {
      let _ = this

      let payload = {
        kfsId: _.$route.params.kfsId,
        // staffAccountId: null,
        treatments: this.selectedTreatments.map( function(x) {
          return {
            treatmentId: x.id,
            qty: 1
          }
        }),
        ..._.model.customer,
        startDate: _.$moment(_.model.bookingDate + ' ' + _.model.bookingTime).utc().format(),
        description: _.model.description,
        paymentMethod: _.selectedPaymentMethod ? _.selectedPaymentMethod.id : null,
      }

      if ( _.selectedPaymentMethod && _.selectedPaymentMethod.id != 3 ) {
        payload.bank = _.selectedPaymentMethod.bank
      }

      return payload
    }
  },
  methods: {
    getTreatment( page ) {
      let _ = this
      _.axios.get('/inventory/treatment'
          + '?page=' + page
          + '&limit=' + _.treatmentLimit
          + '&sortBy=' + _.treatmentSortby
          + '&search=' + _.treatmentSearch
          + '&kfsId=' + _.$route.params.kfsId
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.treatmentCurrentPage = data.currentPage
            _.treatmentTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.treatments.push({ ...elm, selected: false})
            });
          }
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    selectUnselectTreatment( event, treatment ) {
      if ( event.target.checked ) {
        this.selectedTreatments.push(treatment)
      } else {
        let searchSelectedTreatment = this.selectedTreatments.map(x => x.id).indexOf( treatment.id )
        if ( searchSelectedTreatment != -1 ) {
          this.selectedTreatments.splice(searchSelectedTreatment, 1)
        }
      }
    },
    unselectTreatment( treatment ) {
      let searchSelectedTreatment = this.selectedTreatments.map(x => x.id).indexOf( treatment.id )
      if ( searchSelectedTreatment != -1 ) {
        this.selectedTreatments.splice(searchSelectedTreatment, 1)
      }

      let searchTreatment = this.treatments.map(x => x.id).indexOf( treatment.id )
      if ( searchSelectedTreatment != -1 ) {
        this.treatments[searchTreatment].selected = false
      }
    },
    convertMinute( minute ) {
      return parseInt(minute / 60) + ' Jam ' + (minute % 60) + ' Menit'
    },
    generatePayment( payment ) {
      let data = {
        icon: null,
        name: null,
        bank: null,
        va: null,
        bill_code: null,
        bill_key: null,
        qr: null,
        deepLink: null,
      }
      if ( payment.va_numbers && payment.va_numbers.length > 0 ) { //Bank Transfer: BCA, BNI
        if ( payment.va_numbers[0].bank == 'bca' ) {
          data.icon = '/img/pay-1.png'
          data.name = 'BCA Virtual Account'
          data.bank = 'bca'
        } else if ( payment.va_numbers[0].bank == 'bni' ) {
          data.icon = '/img/pay-3.png'
          data.name = 'BNI Virtual Account'
          data.bank = 'bni'
        } else if ( payment.va_numbers[0].bank == 'bri' ) {
          data.icon = '/img/bri.png'
          data.name = 'BRI Virtual Account'
          data.bank = 'bri'
        }
        data.va = payment.va_numbers[0].va_number
      } else if ( payment.bill_key ) { //Bank Transfer: Mandiri
        data.icon = '/img/pay-2.png'
        data.name = 'Mandiri Virtual Account'
        data.bank = 'mandiri'
        data.bill_code = payment.biller_code
        data.bill_key = payment.bill_key
      } else if ( payment.actions && payment.actions.length > 0 ) { //GoPay
          data.icon = '/img/gopay.png'
          data.name = 'Gopay'
          data.qr = payment.actions[0].url
          data.deepLink = payment.actions[1].url
      } else {
        data.name = 'Tunai'
      }
      return data
    },
    async createBookingTreatment() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.axios.post('/sales-order/booking-treatment', _.request)
        .then( resp => {
          _.booking = resp.data.data
          _.paymentDetail = _.generatePayment(_.booking.paymentDetail)
          _.state = 'payment'
        })
        .catch( err => {
          console.log( err )
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    setPaymentMethod( paymentMethod ) {
      let _ = this
      _.selectedPaymentMethod = paymentMethod
      _.createBookingTreatment()
      $('.close-popup').click()
    },
    refresh() {
      window.location.reload()
    }
  },
  mounted() {
    let _ = this
    _.getTreatment(1)

    // let hehe = {"success":true,"message":"Success create booking","data":{"paymentDetail":{"status_code":"201","status_message":"Success, Bank Transfer transaction is created","transaction_id":"28365489-3c75-4a8f-a084-b2b652625794","order_id":"BT-173","merchant_id":"G285547842","gross_amount":"10500.00","currency":"IDR","payment_type":"bank_transfer","transaction_time":"2022-11-08 23:50:33","transaction_status":"pending","va_numbers":[{"bank":"bca","va_number":"47842461101"}],"fraud_status":"accept","expired_payment_time":"2022-11-09 23:50","bank_text":"BCA Virtual Account","bank_image":"https://kezia-api.demoapp.xyz//images/paymentMethod/bca.png"},"id":173,"salesId":234,"downpayment":"10500","paymentMethod":2,"statusDownpayment":false,"noBooking":"BT-173","canceledReason":null,"createdAt":"2022-11-08T16:50:33.540Z","updatedAt":"2022-11-08T16:50:34.276Z","bookingProfile":{"id":173,"name":"123","phoneNumber":"123123123","email":"123@123.com","dob":"2022-11-01","address":"Tes","linkedAccountId":121,"bookingId":173,"createdAt":"2022-11-08T16:50:33.548Z","updatedAt":"2022-11-08T16:50:33.548Z"}}}
    // _.booking = hehe.data
    // _.paymentDetail = _.generatePayment(_.booking.paymentDetail)
    // _.state = 'payment'
  },
  watch: {
    state: function() {
      window.scrollTo(0, 0);
    },
    timePickerOptions: function() {
      this.showTimePicker = false
      setTimeout(() => {
        this.showTimePicker = true
        // this.model.bookingTime = '00:00'
      }, 100);
    }
  }
}
</script>
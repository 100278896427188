import axios from 'axios'

let token = localStorage.getItem('accessToken')
// token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOjM3LCJrZnNJZCI6MSwiaXNBZG1pbiI6ZmFsc2UsInJvbGVJZCI6MywidHlwZSI6ImFjY2VzcyIsImlhdCI6MTY2Nzg4ODYzOSwiZXhwIjoxNjY4MDYxNDM5fQ.Z0_tKFQ-LWgbxWB2RT9rgRGpW0vhwEJPs7Pm71F0R9c'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
axios.defaults.headers.get['Accept'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';

axios.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 401) {
        window.location.reload()
    }
    return Promise.reject(error)
})
export default axios

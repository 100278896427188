import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/helpers/helper'

import axios from './plugins/axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import validate from './plugins/vee-validate.js'
import { ValidationObserver } from 'vee-validate'
Vue.component('ValidationProvider', validate)
Vue.component('ValidationObserver', ValidationObserver)

import Notifications from 'vue-notification'
Vue.use(Notifications)

const moment = require('moment')
require('moment/locale/id')
Vue.use(require('vue-moment'), {moment})

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

import datePicker from 'vue-bootstrap-datetimepicker';
Vue.use(datePicker);

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,[
	{
		name: 'numFormat',
		symbol: '',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	},
	{
		symbol: 'Rp',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	}
])

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
